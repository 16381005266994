<template>
   <Modal :value="value" @input="$emit('input', false)" close-button class="z-50">
      <div class="max-w-lg px-6 py-6">
        <div class="mb-6 text-gray-800">
          <h3 class="text-2xl font-light mb-3">{{ primaryText }}</h3>
          <div v-if="html" v-html="secondaryText"></div>
          <p v-else>{{ secondaryText }}</p>
        </div>
        <div class="flex items-center lg:flex-no-wrap flex-wrap">
          <Button v-if="!alert" class="w-full lg:mr-2 lg:mb-0 mb-6" @click="cancel()">{{ cancelButtonText }}</Button>
          <Button :class="{'lg:ml-2': !alert}" class="w-full " primary @click="approve()">{{ approveButtonText }}</Button>
        </div>
      </div>
    </Modal>
</template>

<script>
export default {
  name: 'DialogModal',
  props: {
    value: {
      type: Boolean,
      value: false,
    },
    html: {
      type: Boolean,
      value: false,
    },
    alert: {
      type: Boolean,
      value: false,
    },
    primaryText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default() {
        return this.$t('cancel');
      },
    },
    approveButtonText: {
      type: String,
      required: false,
      default() {
        return this.$t('approve');
      },
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    approve() {
      this.$emit('approve');
    },
  },
};
</script>
