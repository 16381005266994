<template>
  <NavbarDark align-mid-section="left">
    <div>
      <NavLink :to="{ name: 'datacenter' }" class="mr-5">{{ $t('home') }}</NavLink>
      <NavLink :to="{ name: 'datacenter-chart' }" class="mr-5">{{ $t('editor_chart') }}</NavLink>
      <NavLink :to="{ name: 'datacenter-list' }">{{ $t('editor_list') }}</NavLink>
    </div>
  </NavbarDark>
</template>

<script>
import NavLink from '@/ui/components/NavLink.vue';
import NavbarDark from '@/ui/components/NavbarDark.vue';

export default {
  components: { NavbarDark, NavLink },
};
</script>

<style scoped>

</style>
