<template>
  <svg width="145px" height="51px" viewBox="0 0 145 51" version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <title>851137C4-0964-4FD9-9DE6-352C8A9BEFA3</title>
    <desc>Created with sketchtool.</desc>
    <defs></defs>
    <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Inhaltsdefinitionen" transform="translate(-278.000000, -295.000000)">
        <g id="logo_lowell_weiss" transform="translate(278.000000, 295.000000)">
          <polyline id="Fill-1" fill="#FE5000"
                    points="115.472005 4.13843216 117.257606 5.92471357 119.550707 3.63073869 141.407792 25.4961558 119.550707 47.3615729
                    114.964505 42.7736231 132.235389 25.4961558 113.178905 6.43240704 105.021502 14.5929196 115.920583 25.4961558
                    105.021502 36.399392 109.100203 40.4796482 110.885804 38.6933668 108.592447 36.399392 119.491528 25.4961558
                    108.592447 14.5929196 113.178905 10.0049698 128.664187 25.4961558 111.393304 42.7736231 119.550707 50.9341357
                    144.978993 25.4961558 119.550707 0.0581758794 115.472005 4.13843216"></polyline>
          <path
            d="M29.2561837,28.2598945 C29.2561837,23.0376482 28.1279505,21.4474221 24.3333569,
            21.4474221 C20.5999912,21.4474221 19.4307686,22.8118643 19.4307686,28.2598945
            C19.4307686,33.7081809 20.5999912,35.0828744 24.3333569,35.0828744 C28.1074558,
            35.0828744 29.2561837,33.4926482 29.2561837,28.2598945 Z M16.2617756,28.2598945
            C16.2617756,21.4269196 18.405265,18.8823015 24.3333569,18.8823015 C30.2714399,18.8823015
            32.4049382,21.5294322 32.4049382,28.2598945 C32.4049382,34.9803618 30.2714399,37.647995
            24.3333569,37.647995 C18.405265,37.647995 16.2617756,35.1033769 16.2617756,28.2598945 Z M38.3606979,
            19.1285879 C38.6686307,21.8631106 40.0182067,28.1458492 41.4848587,34.2004975 L45.5563869,22.6065829
            C46.0385247,21.2421407 46.6538781,20.5752965 47.7308746,20.5752965 C48.8281095,20.5752965 49.4847085,
            21.2421407 49.9460954,22.6065829 L53.8639134,34.1697437 C55.3595141,28.1279095 56.7377827,21.8623417
            57.0598057,19.1285879 L60.1573233,19.1285879 C59.7658746,21.9048844 58.054311,28.917 56.2843375,35.5036884
            C55.894682,36.960392 55.1048675,37.647995 53.9563958,37.647995 C52.7769258,37.647995 52.0690901,36.9398894
            51.5667138,35.5036884 L47.6796378,24.412598 L43.6898322,35.4931809 C43.1772085,36.9296382 42.4898675,37.647995
            41.3103975,37.647995 C40.1309276,37.647995 39.3311219,36.9501407 38.9412102,35.4829296 C37.2004417,28.9046985
            35.5203887,21.9041156 35.140212,19.1285879 L38.3606979,19.1285879 Z M62.8925972,28.2598945 C62.8925972,21.6319447
            64.9131184,18.8823015 70.9436837,18.8823015 C77.312924,18.8823015 78.8615548,22.2270302 78.8615548,27.4290302
            C78.8615548,27.9110955 78.8244081,28.5295025 78.7593375,28.9885025 C76.6670848,29.3475528 72.3795936,29.7578593
            69.8362014,29.8708794 L66.0208569,30.0348995 C66.2875442,33.6771709 67.7439488,35.0828744 71.5180477,35.0828744
            C73.9282244,35.0828744 75.9597615,34.8212111 78.0292138,34.1243819 L78.2974382,36.4701256 C76.4915989,37.2320503
            73.7258392,37.647995 70.5335336,37.647995 C64.4824735,37.647995 62.8925972,33.6771709
            62.8925972,28.2598945 Z M70.2361042,27.5930503 C71.7232509,27.5212915 74.1938869,27.3262613 75.6810336,27.1314874
            C75.7087014,26.827794 75.7128004,26.3618744 75.7128004,26.0438291 C75.7128004,23.0889045 74.492341,21.334402
            70.9436837,21.334402 C66.7079417,21.334402 65.9491254,23.724995 65.9491254,27.7880804 L70.2361042,27.5930503 Z
            M86.0887544,12.7743467 L86.0887544,32.4972513 C86.0887544,34.3340201 86.5299028,35.6267035 87.4939223,36.8783819
            L84.7965636,38.2123266 C83.5965989,36.8476281 82.9505035,35.2058894 82.9505035,32.6717789 L82.9505035,12.7743467
            L86.0887544,12.7743467 Z M94.7062633,12.7743467 L94.7062633,32.4972513 C94.7062633,34.3340201 95.1474117,35.6267035
            96.1114311,36.8783819 L93.4140724,38.2123266 C92.2141078,36.8476281 91.5680124,35.2058894 91.5680124,32.6717789
            L91.5680124,12.7743467 L94.7062633,12.7743467 Z M7.02660777,37.4516834 L6.89236749,37.4516834 C3.24687279,37.3709548
            0.106572438,36.7305075 0.106572438,32.0151859 L0.0658392226,13.462206 L3.41979682,13.462206 L3.46027385,30.5787286
            C3.46027385,33.8827085 4.06896643,34.6812814 7.64272968,34.7304874 C10.1963693,34.7304874 11.9338074,34.4506281
            13.7060866,34.2181809 L14.0001855,36.7881709 C12.0537014,37.1705427 10.0652032,37.4516834 7.02660777,37.4516834 Z"
            id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
</style>
