<template>
  <!--
  This view provides the Header and Footer for all child components.
  -->
  <div class="flex flex-col" :class="overflowDataCenter">
    <!-- Dark data center navbar -->
    <DataCenterNavbar v-if="isDataCenter"/>
    <!-- Dark default navbar -->
    <NavbarDark v-else>
      <div>{{ routeKey }}</div>
    </NavbarDark>
    <!-- Padding for navbar height -->
    <div id="padding-nav-height"></div>
    <!-- Router View -->
    <div :class="{
      'lg:min-h-screen-action-bar min-h-screen-action-bar-small': currentRouteIsMemberArea,
      'min-h-screen-action-bar': !currentRouteIsMemberArea }">
      <router-view/>
    </div>
    <!-- Footer -->
    <Footer v-if="!currentRouteIsMemberArea" class="z-10">
      <template v-slot:logo>
        <Logo/>
      </template>
      <FooterItem href="https://www.intratech.de/kontakt/" target="_blank">Kontakt</FooterItem>
      <FooterItem href="https://www.intratech.de/impressum/" target="_blank">Impressum</FooterItem>
      <FooterItem href="https://www.intratech.de/datenschutzerklaerung/" target="_blank">Datenschutz</FooterItem>
    </Footer>
    <MemberAreaFooter v-if="currentRouteIsMemberArea" />
  </div>
</template>

<script>
import Logo from '@/ui/views/DataCenter/components/Logo.vue';
import NavbarDark from '@/ui/components/NavbarDark.vue';
import DataCenterNavbar from '@/ui/views/DataCenter/components/DataCenterNavbar.vue';
import MemberAreaFooter from '@/ui/views/MemberArea/components/MemberAreaFooter.vue';

export default {
  components: {
    DataCenterNavbar, NavbarDark, Logo, MemberAreaFooter,
  },
  computed: {
    routeKey() {
      const routeHasLabel = this.$route.meta && this.$route.meta.routeKey;
      return routeHasLabel ? this.$t(this.$route.meta.routeKey) : '';
    },
    overflowDataCenter() {
      return { 'overflow-y-hidden': this.$route.name === 'datacenter' };
    },
    isDataCenter() {
      return this.$route.path.indexOf('datacenter') >= 0;
    },
    currentRouteIsMemberArea() {
      return this.$route.path.indexOf('member-area') >= 0;
    },
  },
};
</script>

<style scoped lang="scss">
#padding-nav-height {
  height: 80px; /* equals navbar height */
}
</style>
