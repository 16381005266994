<template>
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 78 70" style="enable-background:new 0 0 78 70;" xml:space="preserve">
    <g>
<path class="st0" d="M77.3,38.4l0-1.1c0-3.1-0.3-5.2-4.4-6.8c-2-0.8-5.5-1.6-10.4-2.3c4-3.1,8.1-6,10.5-7.5L72.3,19
c-9,2.5-23.6,5.6-26.8,3.8c-3.2-1.7-7-14.9-8.6-22.8l-1.8,0c-1.8,7.9-5.7,21.1-8.6,22.9c-3.4,2-18,0.9-23.4,0.3l-0.5,1.7
c0.1,0,3.3,1.5,7,3.8c-1.9,0.4-3.5,0.9-4.9,1.4C0,31.9,0,33,0,36.9l0,0.4l0,1.1c-0.1,2.5-0.2,4.3,4.7,6.2c3,1.2,7,2.3,15.2,2.9
c-1.6,9.3-5.6,21.5-5.6,21.7l1.6,0.8c5.8-7.6,15.7-19.1,19.6-19.7c4.4-0.6,17,6.4,21.4,9.1l1.2-1.3c-1.5-2-4.2-6.3-5.9-10.4
c9.8-0.5,16.6-1.6,20.7-3.3C77.5,42.5,77.4,40.8,77.3,38.4z"/>
    <path class="st0" d="M66.8,33.6c-0.5,0-0.8,0-1,0c0,1,0.1,1.9,0.1,2.9c0.2,0,0.5,0,0.8,0c1.1,0,1.7-0.5,1.7-1.4
C68.3,34.2,67.8,33.7,66.8,33.6z"/>
    <path class="st0" d="M56,32.9C56,32.9,56,32.9,56,32.9c-0.1,0.8-0.3,2-0.4,2.8c-0.1,0.9-0.3,1.7-0.5,2.6c0.7,0,1.4,0,2.1,0
c-0.2-0.9-0.3-1.7-0.5-2.6C56.5,34.9,56.2,33.7,56,32.9z"/>
</g>
  <path class="st1" d="M60,27.9c2.8-2.2,5.5-4.2,7.8-5.8c-7.2,1.8-19.5,4.3-23.1,2.4c-3.9-2.1-7.2-13.9-8.7-20.2
c-1.6,6.3-5,18.2-8.6,20.2c-3.3,1.9-13.6,1.5-19.8,1c1.4,0.8,3,1.7,4.7,2.8c6.3-1.1,14.8-1.6,25.6-1.6C46.8,26.7,54.2,27.2,60,27.9z"/>
    <path class="st1" d="M21.7,47.6c-1,5.9-2.9,12.8-4.2,17.3c4.7-5.8,13.3-15.8,17.7-16.4c4.3-0.6,14,4.4,19.2,7.4
c-1.3-2.1-3-5.1-4.2-8.1c-3.7,0.2-7.9,0.2-12.5,0.2C31.2,48.1,26,47.9,21.7,47.6z"/>
    <path class="st1" d="M5.8,42.6c1,0.2,1.9,0.3,2.9,0.4c-0.1-1.4-0.1-2.9-0.1-4.4c1.2,0,2.5,0.1,3.8,0.1c0-0.9,0-1.8,0-2.7
c-1.3,0-2.5,0.1-3.8,0.1c0-0.7,0-1.4,0-2.1c1.3-0.1,2.7-0.2,4.1-0.3c0-0.9,0.1-1.8,0.2-2.7c-2.4,0.3-4.8,0.6-7.1,1V42.6z"/>
    <path class="st1" d="M17.8,30.5c-1,0.1-2,0.2-3,0.3c-0.4,4.2-0.4,8.7,0,12.9c1,0.1,2,0.2,3,0.3C17.4,39.7,17.4,34.9,17.8,30.5z"/>
    <path class="st1" d="M22.1,44.4c1,0.1,2,0.1,3.1,0.2C24.8,41,24.8,37,25,33.2c1,0,2-0.1,3-0.1c0.1-1.1,0.1-2.2,0.2-3.3
c-3,0.1-6,0.3-8.9,0.6c-0.1,1-0.2,2-0.2,3.1c1,0,1.9-0.1,2.9-0.1C21.7,37,21.8,40.9,22.1,44.4z"/>
    <path class="st1" d="M36.6,41.9c-1.2,0-2.3-0.5-3-1c-0.2,1.1-0.3,2.2-0.5,3.3c0.7,0.4,2,0.9,3.4,0.9c3.3,0,4.9-2.1,4.9-4.8
c0-2.2-0.9-3.7-3-4.8c-1.5-0.8-2.1-1-2.1-1.8c0-0.7,0.5-1.2,1.5-1.2c1.2,0,2,0.4,2.5,0.7c0.2-1.1,0.4-2.2,0.6-3.2
c-0.8-0.4-1.7-0.7-3.1-0.7c-2.8,0-4.7,2-4.7,4.7c0,2.3,1.3,3.8,3.3,4.7c1.3,0.6,1.8,1,1.8,1.8C38.1,41.4,37.6,41.9,36.6,41.9z"/>
    <path class="st1" d="M51.1,29.9c-3-0.2-5.9-0.2-8.9-0.3c0,1.1,0.1,2.2,0.1,3.3c1,0,2,0,3,0c0.1,3.9,0.1,8.2-0.1,11.9
c1,0,2-0.1,3.1-0.1c0.3-3.6,0.4-7.8,0.2-11.7c1,0,2,0.1,3,0.1C51.3,32,51.2,31,51.1,29.9z"/>
    <path class="st1" d="M53.8,30.1c-0.6,4.5-1.8,9.9-3.3,14.6c1.1-0.1,2.1-0.1,3.2-0.2c0.3-1.1,0.6-2.2,0.9-3.3c1,0,2-0.1,2.9-0.1
c0.2,1.1,0.3,2.1,0.5,3.1c1.1-0.1,2.2-0.2,3.3-0.3c-0.7-4.1-1.8-9-3.4-13.5C56.6,30.3,55.2,30.2,53.8,30.1z M55.1,38.3
c0.2-0.9,0.3-1.8,0.5-2.6c0.2-0.8,0.3-2,0.4-2.8c0,0,0,0,0,0c0.2,0.8,0.5,2,0.7,2.8c0.2,0.9,0.4,1.7,0.5,2.6
C56.5,38.3,55.8,38.3,55.1,38.3z"/>
  <path class="st1" d="M69.4,37.7C69.4,37.6,69.4,37.6,69.4,37.7c0.8-0.4,1.8-1.3,1.8-2.7c0-1-0.4-1.8-1.1-2.5c-0.8-0.7-2-1.2-3.7-1.4
c-1.5-0.2-2.8-0.2-3.6-0.1c0.4,4.2,0.4,8.6,0,12.7c1-0.1,2-0.2,3-0.3c0.1-1.5,0.2-3,0.2-4.6c0.2,0,0.3,0,0.5,0
c0.9,0,1.3,0.3,1.5,1.6c0.3,1.4,0.5,2.3,0.7,2.6c1-0.1,2-0.3,3-0.4c-0.2-0.4-0.6-1.9-0.9-3C70.5,38.7,70.1,38,69.4,37.7z M66.7,36.5
c-0.2,0-0.5,0-0.8,0c0-1,0-1.9-0.1-2.9c0.1,0,0.4,0,1,0c1,0.1,1.5,0.6,1.5,1.5C68.4,35.9,67.8,36.5,66.7,36.5z"/>
</svg>
</template>

<script>
export default {
  name: 'FitStarLogo',
};
</script>

<style scoped>
  .st0{fill:#E3000F;}
  .st1{fill:#F9E300;}
</style>
