<template>
  <div>
    <div>
      <ActionBar
        class="navbar"
        :class="{
          'lg:bg-gray-800 bg-white': currentRouteIsMemberArea,
          'bg-gray-800': !currentRouteIsMemberArea
        }"
        :alignMidSection="alignMidSection"
      >
        <template v-slot:left>
          <Logo v-if="!currentRouteIsMemberArea" />
          <FitStarLogo class="pl-0 p-2 h-20" v-if="currentRouteIsMemberArea" />
        </template>
        <div class="text-white">
          <slot/>
        </div>
        <template v-slot:right>
          <div
            class="flex items-center"
            :class="{'text-white': !currentRouteIsMemberArea, 'text-gray-800 lg:text-white': currentRouteIsMemberArea}"
          >
            <div class="text-xs text-right">
              <div v-if="user">{{ user.name }}</div>
              <div v-if="configs">{{ configs.branchName }}</div>
            </div>
            <IconLink
              v-if="authToken && isAppActive && !currentRouteIsMemberArea"
              class="ml-4"
              iconName="grid"
              dark
              :memberArea="currentRouteIsMemberArea"
              @click="exitModal = true"
            />
            <IconLink
              class="ml-4"
              iconName="cog-outline"
              v-if="authToken && hasIbsNewsRight"
              @click="gotoMessages"
              :active="currentRouteIsMessages"
              :disabled="currentRouteIsMessages"
              dark
            />
            <IconLink class="ml-4" iconName="logout" :dark="!currentRouteIsMemberArea" v-if="authToken" @click="logoutModal = true" />
          </div>
        </template>
      </ActionBar>
    </div>
     <DialogModal
      v-model="logoutModal"
      :primary-text="$t('reallyWantToLogout')"
      :secondary-text="$t('unsavedDataIsLost')"
      :approve-button-text="$t('logout')"
      @cancel="logoutModal = false"
      @approve="logout"
    />
     <DialogModal
      v-model="exitModal"
      :primary-text="$t('reallyWantToExit')"
      :secondary-text="$t('unsavedDataIsLost')"
      :approve-button-text="$t('exitApp')"
      @cancel="exitModal = false"
      @approve="exit"
    />
  </div>
</template>
<script>
import Logo from '@/ui/views/DataCenter/components/Logo.vue';
import FitStarLogo from '../views/MemberArea/components/FitStarLogo.vue';
import DialogModal from './DialogModal.vue';
import IconLink from './IconLink.vue';

export default {
  components: {
    Logo, DialogModal, IconLink, FitStarLogo,
  },
  props: {
    alignMidSection: {
      required: false,
      type: String,
      default: 'center',
      validator: (prop) => ['left', 'center', 'right'].includes(prop),
    },
  },
  data() {
    return {
      logoutModal: false,
      exitModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    configs() {
      return this.$store.state.contract.configs;
    },
    authToken() {
      return this.$store.state.authentication.authToken;
    },
    isAppActive() {
      return this.$route.name !== 'apps';
    },
    hasIbsNewsRight() {
      return this.$store.state.authentication.appRights.some((e) => e === 'IBS_NEWS');
    },
    currentRouteIsMessages() {
      return this.$route.name === 'messages';
    },
    currentRouteIsMemberArea() {
      return this.$route.path.indexOf('member-area') >= 0;
    },
  },
  methods: {
    gotoMessages() {
      if (!this.routeIsMessages) {
        this.$router.push({ name: 'messages' });
      }
    },
    prepareExit() {
      this.$store.commit('resetSavedContractData');
      this.$store.commit('resetNewContract');
      this.$store.dispatch('removeSearchResults');
      this.$store.dispatch('resetDatacenter');
      this.$store.dispatch('resetDatacenterList');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$store.commit('setConfig', null);
      this.prepareExit();
      this.logoutModal = false;
      this.$nextTick(() => {
        if (this.currentRouteIsMemberArea) {
          this.$router.push({ name: 'member-login', params: { studio: this.$route.params.studio } });
        } else {
          this.$router.push({ name: 'login', params: { studio: this.$route.params.studio } });
        }
      });
    },
    exit() {
      this.prepareExit();
      this.exitModal = false;
      this.$nextTick(() => {
        this.$router.push({ name: 'apps' });
      });
    },
  },
};
</script>

<style scoped>
.navbar {
  height: 80px;
}
</style>
