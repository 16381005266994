<template>
  <div class="bg-gray-800 lg:h-full h-10 flex flex-row">
    <FitStarLogo class="pl-6 h-20 p-2 hidden lg:flex"/>
    <div class="w-full flex items-center justify-center flex-wrap">
      <FooterItem class="remove-margin" href="https://www.fit-star.de/faq/" target="_blank">FAQ</FooterItem>
      <FooterItem href="https://www.fit-star.de/agb/" target="_blank">AGB</FooterItem>
      <FooterItem href="https://www.fit-star.de/impressum/" target="_blank">Impressum</FooterItem>
      <FooterItem href="https://www.fit-star.de/datenschutz/" target="_blank">Datenschutz</FooterItem>
    </div>
  </div>
</template>

<script>
import FitStarLogo from '@/ui/views/MemberArea/components/FitStarLogo.vue';

export default {
  name: 'Footer',
  components: { FitStarLogo },
};
</script>

<style lang="scss">

.remove-margin {
  margin: 0px !important;
}

</style>
