<template>
  <div class="apps__icon_link_main">
    <button
      class="focus:outline-none w-full h-full"
      :class="{'pointer-events-none': disabled}"
      @click="onClick"
    >
      <div v-if="loading" class="transform scale-50 absolute">
        <LoadingCircle class="apps__icon_link_loading-circle-pos"/>
      </div>
      <div v-else>
        <div v-if="customIcon"
          @mouseover="setMouseOverColor"
          @mouseout="setMouseOutColor"
          @mouseleave="setMouseOutColor"
          @focus="setFocusColor"
          @click="setMouseOutColor"
        >
          <component :is="iconName" :color="customIconColor" class="w-full h-full"/>
        </div>
        <Icon
          v-else
          :id="id"
          :name="iconName"
          class="apps__icon_link_icon"
          :class="{
            'text-gray-600': disabled,
            'text-gray-800 lg:text-white': !disabled && !dark && memberArea,
            'text-white' : !disabled && !dark && !memberArea,
            'text-white': dark,
            'text-primary-500': active
          }"
        />
      </div>
    </button>
  </div>
</template>

<script>
import LoadingCircle from './LoadingCircle.vue';

export default {
  data() {
    return {
      customIconColor: '#434A4F',
    };
  },
  components: { LoadingCircle },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    memberArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    fontClass: {
      type: String,
      required: false,
      default: 'text-2xl',
    },
    id: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    customIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    importCustomIconComponent() {
      // eslint-disable-next-line prefer-template
      this.$options.components[this.iconName] = () => import('./icons/' + this.iconName);
    },
    setMouseOverColor() {
      this.customIconColor = '#FE5000';
    },
    setMouseOutColor() {
      this.customIconColor = '#434A4F';
    },
    setFocusColor() {
      this.customIconColor = '#D24200';
    },
    setDisabledColor() {
      this.customIconColor = '#8E9295';
    },
    setActiveColor() {
      this.customIconColor = '#FE5000';
    },
  },
  created() {
    if (this.customIcon) {
      this.importCustomIconComponent();
    }
  },
  mounted() {
    if (this.active) {
      this.setActiveColor();
    } else if (this.disabled) {
      this.setDisabledColor();
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.setActiveColor();
      }
    },
    disabled() {
      if (this.disabled && !this.active) {
        this.setDisabledColor();
      } else {
        this.setMouseOutColor();
      }
    },
  },
};

</script>

<style scoped lang="scss">

.apps__icon_link_icon {
  &:focus {
      @apply text-primary-700;
  }
  &:hover {
      @apply text-primary-500;
  }
  &:active {
      @apply text-primary-700;
  }
}

.apps__icon_link_loading-circle-pos {
  @apply absolute transform;
  --transform-translate-y: -18px;
  --transform-translate-x: 5px;
}
.apps__icon_link_main {
  @apply flex items-center relative;
  min-height: 26px;
  min-width: 20px;
}
</style>
